<template>
    <r-e-dialog title="添加密码" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="700px"
                top="10vh" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="90px" size="small" :model="formPublish" :rules="rules" :inline="true">
            <div class="flex">
                <el-form-item label="密码类型" prop="type" label-width="128px">
                    <el-radio-group v-model="formPublish.type">
                        <el-radio :label="0">在线密码</el-radio>
                        <el-radio :label="1">临时密码</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="有效期" prop="dt1" >
                    <el-date-picker v-model="cycle" :disabled="formPublish.type ===1" type="datetimerange"
                                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
                                    style="width: 100%;" start-placeholder="开始日期" end-placeholder="结束日期"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="密码输入:" prop="password" label-width="128px">
                    <el-input v-model="formPublish.password" style="width: 450px;" placeholder="请输入内容"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号:" prop="phone">
                    <el-input v-model="formPublish.phone" style="width: 450px;" placeholder="请输入内容"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="删除历史密码" prop="isDelete">
                    <el-radio-group v-model="formPublish.isDelete">
                        <el-radio-button :label="false">保留</el-radio-button>
                        <el-radio-button :label="true">删除</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
          <!--      2024-3-11-陈守亮-新增注意参数-->
          <el-form-item  label="注意：" prop="days"  label-width="128px">
            <div style="color: red">删除历史密码将使所有的密码不可用，请谨慎选择！</div>
          </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageError} from "@custom/message";
import {lockTemporary} from "@/api/door-management";
import {timeFormat} from "@custom/index";

export default {
    name: "dialog-set-lock-temporary",
    props: {
        equipmentData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                type: null,
                dt1: null,
                dt2: null,
                password: null,
                phone: null,
                isDelete: true,
            },
            rules: {
                type: [{required: true, message: '请选择密码类型', trigger: 'change'}],
                dt1: [{required: true, message: '请选择开始时间', trigger: 'change'}],
                dt2: [{required: true, message: '请选择结束时间', trigger: 'change'}],
                password: [{required: true, message: '请填写密码', trigger: 'blur'}],
                phone: [{required: true, message: '请填写手机号', trigger: 'blur'}],
                isDelete: [{required: true, message: '请选择是否删除历史密码', trigger: 'change'}],
            },
            cycle: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {},
    methods: {
        openDialog(lockId) {
            const oldFormPublish = this.formPublish;
            let dt1 = timeFormat(new Date());
            let dt2 = timeFormat(new Date((new Date()).getTime() + (8.64e7)));
            this.formPublish = {...oldFormPublish, uuid: lockId, type: 0, dt1, dt2};
            this.cycle = [dt1, dt2];
            this.dialogVisible = true;
        },
        clickCancel() {
            this.dialogVisible = false;
            this.formPublish = {
                type: null,
                dt1: null,
                dt2: null,
                password: null,
                phone: null,
                isDelete: true,
            };
        },
        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    let {uuid, phone, type, dt1, dt2, password, isDelete} = that.formPublish;
                    if (uuid === null) {
                        MessageError('当前房源没有智能门锁无法发送');
                        return;
                    }
                    uuid = that.equipmentData.uuid;
                    lockTemporary({uuid, phone, type, dt1, dt2, password, isDelete}).then(res => {
                        loading.close();
                        MessageSuccess('密码发送成功');
                        that.clickCancel();
                    }).catch(err => {
                        MessageError('密码发送失败');
                        loading.close();
                    });
                }
            });
        },

    },
    watch: {
        'formPublish.type'(value) {
            if (value === 1) {
                const dt1 = timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
                const dt2 = timeFormat(new Date((new Date()).getTime() + (8.64e7)), 'yyyy-MM-dd HH:mm:ss');
                this.formPublish.dt1 = dt1;
                this.formPublish.dt2 = dt2;
                /*this.$set(this.formPublish, "dt1", dt1);
                this.$set(this.formPublish, "dt2", dt2);*/
                this.cycle = [dt1, dt2];
            }
        },
        cycle(value) {
            if (value) {
                let [dt1, dt2] = value;
                /*this.$set(this.formPublish, "dt1", dt1);
                this.$set(this.formPublish, "dt2", dt2);*/
                this.formPublish.dt1 = dt1;
                this.formPublish.dt2 = dt2;
            }
        }
    }
}
</script>

<style scoped>

</style>